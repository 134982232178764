<template>
  <div class="itemcont" v-if="text.text">
    <span class="infotitle tertiary_color" :class="checkImportant">
      {{ title }}
    </span>
    <p v-if="Array.isArray(text.text)">
      <span v-if="text.text != '' && text.text.constructor !== Object">
        <!-- Object.keys(text.text) != 0 && -->
        <span v-for="(item, index) in text.text" :key="index">
          <span v-if="index > 0">, </span>
          <div
            v-if="typeof item == 'object' && type == 'exhibitor'"
            class="url"
          >
            <a class="primary_color" :href="item.url"> {{ item.name }}</a>
          </div>
          <div
            v-else-if="typeof item == 'object' && type == 'product'"
            class="url"
          >
            <button @click="linkTo(item.url)" class="route primary_color">
              {{ item.name }}
            </button>
          </div>
          <span v-else>
            {{ item }}
          </span>
        </span>
      </span>
      <span v-else>
        -
      </span>
    </p>
    <p v-else-if="title == translations.label_info_downloads">
      <button @click="buscarId('downloads')" class="route primary_color">
        {{ translations.btn_downloads }}
      </button>
    </p>
    <p v-else>
      <a
        class="primary_color"
        :href="text.text.link"
        v-if="
          typeof text.text == 'object' &&
            text.text.link &&
            text.text.link.length >= 0
        "
        target="”_blank”"
      >
        {{ text.text.text }}</a
      >
      <span v-else>
        {{ text.text ? text.text : '-' }}
      </span>
    </p>
  </div>
</template>
<!-- <button @click="buscarId('downloads')" class="route primary_color">
              {{ item.name }}
            </button> -->
<script scoped>
  import { mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'MoreInfoItem',
    props: {
      type: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      text: {
        type: Object,
        default: {
          text: 'Huawei Co. Ltd.',
          link: 'url',
        },
      },
      important: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['translations']),
      checkImportant() {
        return this.important ? 'fourth_color' : ''
      },
    },
    methods: {
      linkTo(id) {
        if (this.type == 'product') {
          router.push('/exhibitor/' + id)
        }
      },
      buscarId(id) {
        document.getElementById(id).scrollIntoView()
      },
    },
  }
</script>

<style scoped>
  .url {
    display: inline-block;
  }

  .infotitle {
    display: block;
    font-weight: bold;
  }
  p a,
  .route {
    text-decoration: underline;
  }
  .route {
    font-size: 16px;
    height: auto;
  }
  .itemcont,
  .itemcont * {
    width: 100%;
  }
  .itemcont p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 98%;
  }
</style>
